function ChatNote({ lang, business_data, roomStatus, toast, goToBottomSideBar }) {
  const [note, setNote] = React.useState("");
  const [tempNote, setTempNote] = React.useState("");
  const [lastEditedTime, setLastEditedTime] = React.useState("");
  const [editNote, setEditNote] = React.useState(false);
  const [editedName, setEditedName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [editedLoading, setEditedLoading] = React.useState(false);
  const [isNoteExpanded, setIsNoteExpanded] = React.useState(false);

  const textareaRef = React.useRef(null);

  React.useEffect(() => {
    getNote();
  }, [roomStatus.chat_id]);

  React.useEffect(() => {
    if (editNote && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(tempNote.length, tempNote.length);
      adjustTextareaHeight(true);
    }
  }, [editNote]);

  const getNote = () => {
    setLoading(true);
    axios({
      method: "GET",
      url:
        backend +
        "chat/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/" +
        roomStatus.chat_id +
        "/" +
        roomStatus.pid +
        "/" +
        business_data.app_id +
        "/chat-note",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
      },
    })
      .then((res) => {
        if (res && res.data && res.data.result === 0) {
          setNote(res?.data?.data?.note ? res?.data?.data?.note : "");
          setTempNote(res?.data?.data?.note ? res?.data?.data?.note : "");
          setLastEditedTime(
            res?.data?.data?.update_time
              ? moment(res?.data?.data?.update_time)
                  .add(7, "hours")
                  .format("DD MMM YYYY")
              : ""
          );
          setEditedName(
            res?.data?.data?.displayname ? res?.data?.data?.displayname : ""
          );
          setEditNote(false);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(lang.__error);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(lang.__error);
      });
  };

  const saveNote = () => {
    setEditedLoading(true);
    // Remove leading and trailing whitespace and new lines, but keep whitespace and new lines between text
    const processedNote = tempNote
      .replace(/^\s+|\s+$/g, "")
      .replace(/^\n+|\n+$/g, "");
    axios({
      method: "POST",
      url:
        backend +
        "chat/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/" +
        roomStatus.chat_id +
        "/" +
        roomStatus.pid +
        "/" +
        business_data.app_id +
        "/chat-note",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
      },
      data: {
        note: processedNote,
      },
    })
      .then((res) => {
        if (res && res.data && res.data.result === 0) {
          setNote(processedNote);
          setTempNote(processedNote);
          setLastEditedTime(
            moment(new Date()).format("DD MMM YYYY")
          );
          setEditedName(business_data.display_name);
          setEditedLoading(false);
          setEditNote(false);
          setIsNoteExpanded(true);
          toast.success(lang.__success);
        } else {
          setEditedLoading(false);
          toast.error(lang.__error);
        }
      })
      .catch((err) => {
        setEditedLoading(false);
        toast.error(lang.__error);
      });
  };

  const adjustTextareaHeight = (reset) => {
    const textarea = textareaRef.current;
    if (textarea) {
      if (reset) {
        textarea.style.height = "auto";
      }
      textarea.style.height = `${textarea.scrollHeight + 2}px`;
    }
  };

  return (
    <div className="chat-note">
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i class="icon-circle-notch animate-spin"></i>
        </div>
      ) : (
        <>
          <div
            className={`accordion-header -open`}
            style={{ cursor: "default" }}
          >
            <p className="sp-text -gap">{lang.__note}</p>
            {!editNote ? (
              <p
                className="sp-link -gap -hover"
                style={{
                  cursor: "pointer",
                  marginBottom: "0px",
                  padding: "2px 8px",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  setEditNote(!editNote);
                  goToBottomSideBar("note-char-count");
                }}
              >
                {note.length > 0 ? lang.__edit : lang.__add}
              </p>
            ) : null}
          </div>
          {editNote ? (
            <div className="accordion-body" style={{ padding: "0px" }}>
              <textarea
                ref={textareaRef}
                style={{
                  padding: "4px",
                  resize: "vertical",
                  width: "100%",
                  border: tempNote.length > 1000 && "1px solid red",
                  outlineColor: tempNote.length > 1000 && "red",
                }}
                value={tempNote}
                onChange={(e) => {
                  setTempNote(e.target.value);
                  adjustTextareaHeight(e.target.value.length < tempNote.length);
                }}
              />
              <p
                id="note-char-count"
                className="sp-text-help"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: tempNote.length > 1000 && "red",
                }}
              >
                {tempNote.length.toLocaleString()} / 1,000
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <ButtonCancel
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setEditNote(false);
                    setTempNote(note);
                  }}
                >
                  {lang.__cancel}
                </ButtonCancel>
                <button
                  className="sp-btn -blue"
                  style={{ fontSize: "14px", marginLeft: "10px" }}
                  disabled={
                    (tempNote.length === 0 && note.length === 0) ||
                    tempNote.length > 1000
                  }
                  onClick={() => {
                    saveNote();
                  }}
                >
                  {" "}
                  {editedLoading ? (
                    <i
                      style={{ margin: "0px" }}
                      class="icon-circle-notch animate-spin"
                    ></i>
                  ) : (
                    lang.__save
                  )}
                </button>
              </div>
            </div>
          ) : editedName ? (
            <div
              className="accordion-body"
              style={{ paddingBottom: "8px", marginTop: "2px" }}
            >
              <div>
                {note.length > 0 ? (
                  <span
                    style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                  >
                    {isNoteExpanded
                      ? note
                      : (() => {
                          const truncatedNote = note.substring(0, 300);
                          const lastChar = truncatedNote.slice(-1);
                          const trimmedNote =
                            lastChar === " "
                              ? truncatedNote.trimEnd()
                              : truncatedNote;
                          return trimmedNote + (note.length > 300 ? "..." : "");
                        })()}
                    {note.length > 300 && (
                      <div>
                        <a
                          className="sp-link"
                          onClick={() => {
                            setIsNoteExpanded(!isNoteExpanded);
                          }}
                        >
                          {isNoteExpanded ? lang.__less : lang.__more}
                        </a>
                      </div>
                    )}
                  </span>
                ) : (
                  <p className="sp-text-help">
                    {lang.__no_note}
                  </p>
                )}
                <span style={{ display: "inline" }}>
                  <p
                    id="note-edit-detail"
                    className="sp-text-help -ellipsis"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      marginTop: "4px",
                    }}
                  >
                    {lang.__last_edited_on} {lastEditedTime} {lang.__by}{" "}
                    {editedName}
                  </p>
                </span>
              </div>
            </div>
          ) : (
            <div
              className="accordion-body"
              style={{ paddingBottom: "8px", marginTop: "2px" }}
            >
              <p className="sp-text-help">
                {lang.__no_note}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
